<template>
  <v-list>
    <v-list-subheader>{{ $t("general") }}</v-list-subheader>

    <v-dialog width="600">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="add" :title="$t('admin.organizations.organization')" />
      </template>

      <template #default="{ isActive }">
        <v-card :title="$t('admin.organizations.create')">
          <organization-form
            @submit="
              (org) => {
                isActive.value = false
                $router.push(`/admin/organizations/${org.type.toLowerCase()}/${org._id}`)
              }
            "
            @cancel="
              () => {
                isActive.value = false
              }
            "
          />
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="1000">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="add" :title="$t('admin.users.user')" />
      </template>

      <template #default="{ isActive }">
        <v-card :title="$t('admin.users.create')">
          <user-form
            @ok="
              (user) => {
                isActive.value = false
                $router.push(`/admin/users/${user._id}`)
              }
            "
            @cancel="
              () => {
                isActive.value = false
              }
            "
          />
        </v-card>
      </template>
    </v-dialog>

    <v-list-subheader>{{ $t("assets.title") }}</v-list-subheader>

    <v-dialog width="600">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="add" :title="$t('assets.structure.componentGroups.componentGroup')" />
      </template>

      <template #default="{ isActive }">
        <v-card :title="$t('assets.structure.componentGroups.add')">
          <component-group-form
            @submit="
              (group) => {
                isActive.value = false
                $router.push(`/admin/asset-structure?selectedComponentGroupId=${group._id}`)
              }
            "
            @cancel="
              () => {
                isActive.value = false
              }
            "
          />
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="600">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="add" :title="$t('assets.structure.components.component')" />
      </template>

      <template #default="{ isActive }">
        <v-card :title="$t('assets.structure.components.add')">
          <component-form
            @submit="
              (component) => {
                isActive.value = false
                $router.push(`/admin/asset-structure?selectedComponentId=${component._id}`)
              }
            "
            @cancel="
              () => {
                isActive.value = false
              }
            "
          />
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="600">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="add" :title="$t('admin.assets.lookupTypes.lookupType')" />
      </template>

      <template #default="{ isActive }">
        <v-card :title="$t('admin.assets.lookupTypes.lookupType')">
          <lookup-type-form
            @submit="
              (lookupType) => {
                isActive.value = false
                $router.push(`/admin/lookup-types/${lookupType._id}`)
              }
            "
            @cancel="
              () => {
                isActive.value = false
              }
            "
          />
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="600">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="add" :title="$t('admin.assets.lookupValues.lookupValue')" />
      </template>

      <template #default="{ isActive }">
        <v-card :title="$t('admin.assets.lookupValues.lookupValue')">
          <lookup-value-form
            @submit="
              (lookupValue) => {
                isActive.value = false
                $router.push(`/admin/lookup-values/${lookupValue._id}`)
              }
            "
            @cancel="
              () => {
                isActive.value = false
              }
            "
          />
        </v-card>
      </template>
    </v-dialog>
  </v-list>
</template>

<script setup lang="ts">
const $router = useRouter()
</script>
