<template>
  <v-list density="comfortable" class="pt-0" theme="dark">
    <side-menu-item v-for="menuItem in primaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />

    <v-divider class="my-2" />
    <v-list-subheader v-if="!rail">{{ $t("admin.assets.title") }}</v-list-subheader>
    <side-menu-item v-for="menuItem in assetMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />

    <v-divider class="my-2" />
    <v-list-subheader v-if="!rail">{{ $t("utilities") }}</v-list-subheader>
    <side-menu-item v-for="menuItem in secondaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />

    <v-divider class="my-2" />
    <v-list-subheader v-if="!rail">{{ $t("server") }}</v-list-subheader>
    <side-menu-item v-for="menuItem in serverMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />
  </v-list>
</template>

<script setup lang="ts">
import type { MenuItem } from "./SideMenuItem.vue"

defineProps<{ rail?: boolean }>()

const primaryMenuList: Array<MenuItem> = [
  {
    title: "dashboard.title",
    icon: "$adminPanel",
    to: "/admin",
    exact: true,
  },
  {
    title: "admin.organizations.title",
    icon: "$organization",
    to: "/admin/organizations",
  },
  {
    title: "admin.users.title",
    icon: "$users",
    to: "/admin/users",
  },
  {
    title: "admin.organizations.keys.apiKeys",
    icon: "$apiKey",
    to: "/admin/api-keys",
  },
  {
    title: "tickets.title",
    icon: "$ticket",
    to: "/admin/tickets",
  },
  {
    title: "admin.defaultListValues.menuTitle",
    icon: "$listValue",
    to: `/admin/list-values`,
  },
]

const assetMenuList: Array<MenuItem> = [
  {
    title: "admin.assets.title",
    icon: "$asset",
    to: "/admin/assets",
  },
  {
    title: "admin.assets.templates.title",
    icon: "$assetTemplates",
    to: `/admin/templates`,
  },
  {
    title: "assets.structure.title",
    icon: "$assetStructure",
    to: `/admin/asset-structure`,
  },
  {
    title: "admin.assets.lookupTypes.title",
    icon: "$lookupType",
    to: `/admin/lookup-types`,
  },
  {
    title: "admin.assets.lookupValues.title",
    icon: "$lookupValue",
    to: `/admin/lookup-values`,
  },
  {
    title: "admin.assets.componentTypes.title",
    icon: "$componentType",
    to: `/admin/component-types`,
  },
]

const secondaryMenuList: Array<MenuItem> = [
  {
    title: "admin.releaseNotes.title",
    icon: "$releaseNotes",
    to: "/admin/release-notes",
  },
  {
    title: "admin.scripts.title",
    icon: "$scripts",
    to: "/admin/scripts",
  },
  {
    title: "admin.securityLogs.title",
    icon: "$securityLogs",
    to: "/admin/security-logs",
  },
  {
    title: "mutationLog.title",
    icon: "$mutationLogs",
    to: "/admin/mutation-log",
  },
]

const serverMenuList: Array<MenuItem> = [
  {
    title: "Mongo",
    icon: "library_books",
    to: "/admin/server/mongo",
  },
]
</script>
